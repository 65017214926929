export const environment = {
  production: false,
  versionCheckURL: './version.json',
  apiUrl: 'https://curapatient.dev.wg.curapatient.com',
  apiSocketUrl: 'https://curapatient-socket.dev.wg.curapatient.com',
  apiAuthUrl: 'https://kc.dev.wg.curapatient.com',
  ssoLogoutUrl: 'https://ssotest.walgreens.com/idp/idpLogout?TargetResource=',
  webUrl: 'https://curapatient-fe.dev.wg.curapatient.com',
  name: 'walgreens-dev',
  carePlanId: 1314,
  defaultPODClinicId: '26fed875-bf66-4c4f-a99f-da354c93b64a',
  wgCareplanIdArray: [1314,32638, 32639, 32640],
  ltcfCarePlanId: 33632,
  ltcfDayoffAssessmentID: 339,
  patientRegistration: {
    assessmentId: 338,
    organizationId: 'e88ed8a5-e28b-4e01-99a7-dc44dc44920f',
  },
  walgreenHeadquarterId: 'da84915b-0be0-4ec7-adac-96516227be65',
  carePlans: [
    {name: 'COVID-19 Vaccine', type: 'vaccine'},
    {name: 'COVID-19 Vaccine 3rd Dose', type: 'thirdDose'},
    {name: 'COVID-19 Vaccine 3rd Dose', type: 'boosterDose', goalName: 'Dose Booster'},
    {name: 'Flu Shot Care Plan', type: 'fluShot'},
  ],
  tableauDashboardView: 'VaccinationDashboard-Walgreens/VaccinationDashboard'
};
